<template>
  <div class="home-product">
    <LeftTitle title="产品介绍" :havaSlot="true">
      <template v-slot:right>
        <a @click="toggle(-1)" :class="{disabled:index===0}" href="javascript:;"
           class="iconfont icon-angle-left prev"></a>
        <a @click="toggle(1)" :class="{disabled:index===maxIndex}" href="javascript:;"
           class="iconfont icon-angle-right next"></a>
      </template>
      <div ref="target" class="box">
        <Transition name="fade">
          <ul v-if="products.length" class="list" :style="{transform:`translateX(${-index*160}vh)`}">
            <li v-for="(item,index) in products" :key="item.id">
              <RouterLink :to="`/product/detail/${item.productId}`">
                <img v-if="index>3" v-lazy="item.productImage" :alt="item.productName">
                <img v-else :src="item.productImage" :alt="item.productName">
                <p class="name ellipsis">{{ item.productName }}</p>
              </RouterLink>
            </li>
          </ul>
          <!--        <div v-else class="skeleton">-->
          <!--          <BaseSkeleton class="item" v-for="i in 4" :key="i" animated bg="#e4e4e4" width="100vh" height="100vh"/>-->
          <!--        </div>-->
        </Transition>
      </div>
    </LeftTitle>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from 'vue'
import BaseSkeleton from "@/components/library/base-skeleton.vue";
import {findProductByCategoryId} from "@/api/product";
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  name: 'HomeBrand',
  components: {LeftTitle, BaseSkeleton},
  setup() {
    const pageIndex = 1;
    const pageSize = 13;
    const reqParams = reactive({
      pageIndex,
      pageSize,
    })
    const products = ref([])
    let total = 0;
    // 切换效果，前提只有 0 1 两页
    const maxIndex = ref(0)
    const index = ref(0)
    // 1. 点击上一页
    // 2. 点击下一页
    const toggle = (step) => {
      const newIndex = index.value + step
      if (newIndex < 0 || newIndex > maxIndex.value) return
      index.value = newIndex
    }
    onMounted(async () => {
      await findProductByCategoryId(reqParams).then(res => {
        let maxI;
        products.value = res.data.records
        total = res.data.records.length
        let i = Math.floor(total / 4)
        maxI = total % 4 > 0 ? i + 1 : i;
        if (maxI > 0) {
          maxIndex.value =
            maxI - 1
        }
      })
    })
    return {products, toggle, index, maxIndex}
  },
}
</script>

<style scoped lang='less'>
.home-product {
  .skeleton {
    width: 100%;
    display: flex;

    .item {
      margin-right: 10px;

      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }

  .home-panel {
    background: #f5f5f5
  }

  .iconfont {
    width: 20px;
    height: 20px;
    background: #ccc;
    color: #fff;
    display: inline-block;
    text-align: center;
    margin-left: 5px;
    background: @baseColor;

    &::before {
      font-size: 12px;
      position: relative;
      top: -2px
    }

    &.disabled {
      background: #ccc;
      cursor: not-allowed;
    }
  }

  .box {
    display: flex;
    height: 100%;
    overflow: hidden;
    padding-bottom: 40px;

    .list {
      display: flex;
      transition: all 1s;

      li {
        margin-right: 1vh;
        width: 39vh;

        &:nth-child(5n) {
          margin-right: 0;
        }

        img {
          width: 100%;
          height: 28vh;
        }

        p {
          text-align: center;
        }
      }
    }
  }
}
</style>
