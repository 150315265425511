<template>
  <div style="padding-bottom: 90vh">
    <div class="video-container" style="z-index: 100;width: 100%">
      <div>
        <p class="top-video-p1 animated fadeInLeft">智慧物流系统工程</p>
        <p class="top-video-p2 animated fadeInRight">全心服务 智慧物流</p>
      </div>
    </div>
    <div ref="videoContainer" class="video-container">
      <video autoplay loop muted>
        <source
          src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/2024/8/video/20240807184732349.mp4"
          type="video/mp4">
      </video>
    </div>
  </div>
</template>
<script>

import {useRoute} from "vue-router";

export default {
  methods: {useRoute},
  setup() {
  },
};
</script>

<style lang="less" scoped>
.video-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
  transition: height 0.3s ease;
  background-color: rgba(44, 62, 80, 0.2);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover; // 使视频适应容器并保持比例
  }

  div {
    opacity: 1;
    width: 100%;
    height: 100%;

    p {
      // 设置绝对定位
      position: absolute;
      z-index: 999; // 确保文本在其他元素上面
      color: #ffffff;
      text-align: center;
      font-weight: bold;
      line-height: 2;
      opacity: 1;
    }

    .top-video-p1 {
      top: 50%; // 设置顶部位置为父容器的50%
      left: 10%; // 设置左侧位置为父容器的50%
      font-size: 50px;
      transform: translate(50%, -50%); // 平移自身宽度和高度的50%，以实现居中
      opacity: 1;
    }

    .top-video-p2 {
      top: 60%; // 设置顶部位置为父容器的50%
      left: 17%; // 设置左侧位置为父容器的50%
      font-size: 25px;
    }
  }
}

</style>
