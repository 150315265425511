<template>
  <div id="home-index">
    <!-- 首页入口 -->
    <HomeTop/>
    <!-- 产品 -->
    <HomeProduct/>
    <!-- 解决方案 -->
    <HomeAdvice/>
    <!--  关于我们   -->
    <AboutUs/>
    <!--  公司进程   -->
    <DevelopInfo/>
    <!--  技术资源    -->
    <TechnologyResources/>
    <!--  服务客户    -->
    <ServiceCustomer/>
    <!--  企业荣誉   -->
    <CompanyHonor swiper-rows="1" swiper-height="350px"/>
    <!--  知识产权   -->
    <CompanyProperty swiper-rows="1" swiper-row-views="4" swiper-height="350px"/>
    <!-- 新闻资讯 -->
    <HomeNew/>
  </div>
</template>
<script>
import {defineAsyncComponent} from "vue";

import HomeTop from "@/views/home/components/home-top.vue";
import HomeProduct from "@/views/home/components/home-product.vue";

export default {
  name: 'HomeIndex',
  components: {
    HomeTop,
    HomeProduct,
    HomeAdvice: defineAsyncComponent(() => import('./components/home-advice')),
    AboutUs: defineAsyncComponent(() => import('@/views/company/components/about-us.vue')),
    DevelopInfo: defineAsyncComponent(() => import('@/views/company/components/develop-info.vue')),
    TechnologyResources: defineAsyncComponent(() => import('@/views/company/components/technology-resources.vue')),
    ServiceCustomer: defineAsyncComponent(() => import('@/views/company/components/service-customer.vue')),
    CompanyHonor: defineAsyncComponent(() => import('@/views/honor/components/company-honor.vue')),
    CompanyProperty: defineAsyncComponent(() => import('@/views/honor/components/company-property.vue')),
    HomeNew: defineAsyncComponent(() => import('./components/home-new')),
  },
}
</script>
<style scoped lang="less">

#home-index {
  width: 100%;
}
</style>
